import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRefreshToken } from './userSlice';
import { feedbackArticle, getArticle, searchArticle } from '../http/articleService';
import {event} from "react-ga";
import {EVENT} from "../config/analytics";

export const fetchFeedbackArticle = createAsyncThunk(
	'comments/fetchFeedbackArticle',
	async (
		{
			customerUuid,
			articleUuid,
			rate,
			comments
		}: { customerUuid: string; articleUuid: string; rate: boolean; comments?: string },
		thunkAPI
	) => {
		let response = await feedbackArticle(customerUuid, articleUuid, rate, comments);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await feedbackArticle(customerUuid, articleUuid, rate, comments);
		}
		return response.data;
	}
);

export const fetchGetArticle = createAsyncThunk('comments/fetchGetArticle', async (uuid: string, { getState }) => {
	// @ts-ignore
	const article = getState().article.allArticles.find(category => category.article_uuid === uuid);
	if (article) {
		return article;
	} else {
		let response = await getArticle(uuid);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await getArticle(uuid);
		}
		return response.data;
	}
});

export const fetchSearchArticle = createAsyncThunk('comments/fetchSearchArticle', async (query: string, thunkAPI) => {
	let response = await searchArticle(query);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await searchArticle(query);
	}
	if (response.data.length === 0) {
		event({category: EVENT.search.category, action: EVENT.search.actions.not_found, label:  query})
	} else {
		event({category: EVENT.search.category, action: EVENT.search.actions.found, label:  query})
	}
	return response.data;
});

const initialArticle: IArticleState = {
	article: {
		status: 'initial',
		item: {
			article: null,
			active: null,
			cdate: null,
			name: null,
			path: null,
			udate: null,
			uuid: null,
			uuidCategory: null,
			_id: null,
			scenarios: [],
			carousel: []
		}
	},
	resultArticleSearch: [],
	loading: false,
	serverError: false,
	articleFeedback: {
		status: 'initial'
	},
	selectedArticle: {},
	allArticles: []
};

const article: IArticleState = JSON.parse(sessionStorage.getItem('store'))?.article ?? initialArticle;

export interface IArticleState {
	article: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		item: IArticle;
	};
	resultArticleSearch: Array<IArticle>;
	loading: boolean;
	serverError: boolean;
	articleFeedback: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
	};
	selectedArticle: {
		uuid?: string;
		name?: string;
	};
	allArticles: Array<{ article_uuid: string; item: IArticle }>;
}

export interface ISubcategories {
	_id: string;
	uuid: string;
	uuidTaxonomy: string;
	path: string;
	name: string;
	active: boolean;
	order: number;
	type: string;
	cDate: string;
	articles?: Array<{ name: string; uuid: string; _id: string }>;
	directUuidArticle?: string;
}

export interface IArticle {
	active: boolean;
	article: string;
	cdate: string;
	name: string;
	path: string;
	udate: string;
	uuid: string;
	uuidCategory: string;
	_id: string;
	scenarios: Array<{ title; string; uuid: string; name: string; form: any; description: string }>;
	carousel: Array<{order: number, url: string}>
}

export const articleSlice = createSlice({
	name: 'article',
	initialState: article,
	reducers: {
		setArticle: (state, param) => {
			state.article = param.payload;
		},
		setResultArticle: (state, param) => {
			state.resultArticleSearch = param.payload;
		},
		setLoadingArticle: (state, param: PayloadAction<boolean>) => {
			state.loading = param.payload;
		},
		setServerErrorArticle: (state, param: PayloadAction<boolean>) => {
			state.serverError = param.payload;
		},
		setSelectedArticle: (state, param: PayloadAction<{ uuid: string; name: string }>) => {
			state.selectedArticle = param.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchSearchArticle.fulfilled, (state, action: PayloadAction<Array<IArticle>>) => {
			state.resultArticleSearch = action.payload;
			state.loading = false;
		});
		builder.addCase(fetchSearchArticle.rejected, (state, action) => {
			state.serverError = true;
			state.loading = false;
			console.log({action})
			console.log('hola mundo')
			event({category: EVENT.search.category, action: EVENT.search.actions.error, label:  action.meta.arg})
		});
		builder.addCase(fetchSearchArticle.pending, (state, action) => {
			state.serverError = false;
			state.loading = true;
			state.resultArticleSearch = [];
		});
		builder.addCase(fetchGetArticle.pending, (state, action: PayloadAction<IArticle>) => {
			state.article.status = 'pending';
		});
		builder.addCase(
			fetchGetArticle.fulfilled,
			(state, action: PayloadAction<IArticle | { article_uuid: string; item: IArticle }>) => {
				state.article.status = 'success';

				if ('article_uuid' in action.payload) {
					state.article.item = action.payload.item;
				} else {
					state.allArticles = [...state.allArticles, { item: action.payload, article_uuid: action.payload.uuid }];
					state.article.item = action.payload;
				}
			}
		);
		builder.addCase(fetchGetArticle.rejected, state => {
			state.article.status = 'error';
		});

		builder.addCase(fetchFeedbackArticle.fulfilled, (state, action) => {
			state.articleFeedback.status = 'success';
		});
		builder.addCase(fetchFeedbackArticle.pending, state => {
			state.articleFeedback.status = 'pending';
		});
		builder.addCase(fetchFeedbackArticle.rejected, (state, action) => {
			state.articleFeedback.status = 'error';
		});
	}
});

// Action creators are generated for each case reducer function
export const {
	setArticle,
	setLoadingArticle,
	setServerErrorArticle,
	setResultArticle,
	setSelectedArticle
} = articleSlice.actions;

export const selectArticle = (state: { article: IArticleState }) => state.article.article;
export const selectResultArticleSearch = (state: { article: IArticleState }) => state.article.resultArticleSearch;
export const selectServerErrorArticle = (state: { article: IArticleState }) => state.article.serverError;
export const selectLoadingArticle = (state: { article: IArticleState }) => state.article.loading;
export const selectArticleFeedback = (state: { article: IArticleState }) => state.article.articleFeedback;
export const selectSelectedArticle = (state: { article: IArticleState }) => state.article.selectedArticle;

export default articleSlice.reducer;
