import styles from "../ticketdetail.module.css";
import {createMarkup, timeWithFormat} from "../../../utils";
import {FileType} from "./FileType";

const TicketInfo = ({ticket}) => {

    return(
        <>
            <div className={`m-y-16 ${styles.customer}`}>
                <div dangerouslySetInnerHTML={createMarkup(ticket.ticket?.content)} />
                <span className={styles.time}>{timeWithFormat(ticket.ticket.cdate)}</span>
            </div>
            <div className={styles.containerImgCustomer}>
                {ticket?.ticket?.filesSigned.map(file => {
                    return <FileType url={file}/>
                })}
            </div>
        </>
    )
}

export default TicketInfo;
// https://storage.googleapis.com/public.dev-albo.tech/customer/16110087113327923fdec-0794-498a-a85e-c47bd4b849ef/support/tickets/fe0eea21-1d46-49ae-8822-dd289605ba68/d6b3ca29-c80a-409e-a5a4-8854e14b8f18.png?GoogleAccessId=service-blobstorage%40albo-os-computeplatform-dev.iam.gserviceaccount.com&Expires=1638497991&Signature=Zqpm0cSlAKxPnNsWrfrOVvsR%2BHprHTnH%2Bb3v%2FGXE%2BQ0WUbvQPV2tlq%2FSY49%2BRY5cbz7iEgd3np0XytD5OCHx8HMQbxYj1a1ZBXo2YvlY%2BkPjtfuntxVayw3yIqqB6WeY3ojuLk9M7eZTsZWEPtcgA8kLPpkSEyDG6cn1At%2FqeorFNLoHxcRecAnN673HMmXHhsSJPO6HSvDjx2vPJrkxQ9S2OaqgIziV3BJkq2hRW3zpmttDc%2BW4KIxenJFAS0ZPW7HeS0XaF%2F%2FdwGgf6yBdJ%2F2KrOfWX146tnVaEsB6BSiNbPPyoQopqIAHX3AaFD36Msdw0IoBj0ptqbHeQ6r0uw%3D%3D